import React from "react";
import VoucherRedemption from "../../components/voucher";

const PARTNER = "PRIMROSE";

function Primrose() {
    return <VoucherRedemption partner={PARTNER} />;
}

export default Primrose;
