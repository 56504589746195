import "../../static/scss/voucher.scss";

import { Link } from "gatsby";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { useForm } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";

import { BASE_URL } from "../../constants/requests";
import Layout from "../layout";

const REQUEST_URL = `${BASE_URL}/partners/register`;

/* eslint-disable-next-line */
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function VoucherRedemption(props) {
    const { partner } = props;

    const [netError, setNetError] = useState("");
    const [done, setDone] = useState(false);
    const [working, setWorking] = useState(false);
    const {
        formState: { errors, isSubmitted, isValid },
        handleSubmit,
        register,
        reset,
        watch
    } = useForm();

    const password = useRef(null);
    password.current = watch("password", "");

    const agreesToPrivacy = useRef(null);
    agreesToPrivacy.current = watch("data_storing_consent", false);

    useEffect(() => {
        reset();
    }, [done, reset]);

    const onSubmit = async data => {
        setNetError("");
        setWorking(true);

        try {
            const body = {
                data_storing_consent: true,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                partner,
                password: data.password,
                validation_code: data.validation_code.toUpperCase(),
                weekly_updates: data.weekly_updates
            };
            const response = await fetch(REQUEST_URL, {
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });

            const genericErrorMessage = "There was a problem applying the voucher code you submitted";
            const responseData = await response.json();

            if (responseData.error && responseData.error.code > 400 && responseData.error.message) {
                try {
                    const parsedMessage = JSON.parse(responseData.error.message);
                    if (process.env.NODE_ENV === "development") {
                        /* eslint-disable-next-line */
                        console.log("[DEV] Error response:", parsedMessage);
                    }
                    let errMessage = genericErrorMessage;

                    // check for "OBJECT_ALREADY_EXISTS" error which means there is an existing
                    // account associated with the email that was submitted. Make message more clear.
                    if (parsedMessage.code === "OBJECT_ALREADY_EXISTS") {
                        errMessage = "There is an existing account associated with that email address";
                    } else if (parsedMessage.message) {
                        errMessage = parsedMessage.message;
                    }
                    setNetError(errMessage);
                } catch (err) {
                    setNetError(genericErrorMessage);
                }
            } else if (response.status >= 200 && response.status < 400) {
                setDone(true);
            } else {
                setNetError(genericErrorMessage);
            }
        } catch (error) {
            try {
                const parsedMessage = JSON.parse(error.message);
                if (parsedMessage && parsedMessage.message) {
                    setNetError(parsedMessage.message);
                } else {
                    throw new Error();
                }
            } catch (_err) {
                setNetError(error.message);
            }
        } finally {
            setWorking(false);
        }
    };

    return (
        <Layout
            className="uk-padding-small uk-flex uk-flex-center uk-flex-top code-layout"
            disableNavBar
            style={{
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
                minHeight: "470px",
                paddingBottom: "140px",
                paddingTop: "40px",
                position: "relative"
            }}
        >
            <div className="background-image" />
            <a href="https://www.hopster.tv/">
                <StaticImage
                    alt="logo"
                    className="logo"
                    placeholder="none"
                    src="../../static/images/hopster-pink.png"
                    width={139}
                />
            </a>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="uk-box-shadow-medium code-container">
                    {!done && (
                        <div className="uk-text-center code-container-login">
                            Already have an account? <a href="https://club.hopster.tv/#/login/">Log in here</a>
                        </div>
                    )}
                    {done && <div className="uk-text-center code-container-login">&nbsp;</div>}
                    {!done && <div className="uk-text-center uk-text-large code-title">Join Hopster</div>}
                    {done && <div className="uk-text-center code-title">All Done!</div>}

                    {!!netError && (
                        <div className="uk-text-left form-message error-message">
                            <div>{netError}</div>
                        </div>
                    )}
                    {!done && isSubmitted && !isValid && !netError && (
                        <div className="uk-text-left form-message error-message">
                            <div>Please correct the form errors</div>
                        </div>
                    )}

                    {done && (
                        <div className="uk-text-center code-container-row">
                            <span>
                                Your account was successfully created.
                                <br />
                                <a href="https://club.hopster.tv/#login/">Log in here</a>
                            </span>
                            <br />
                            <br />
                        </div>
                    )}

                    {!done && (
                        <>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="validation_code"
                                        type="text"
                                        className="upper-input"
                                        placeholder="Hopster voucher code"
                                        {...register("validation_code", {
                                            required: "Please enter a voucher code"
                                        })}
                                    />
                                    {!!errors.validation_code && (
                                        <div className="error-text">{errors.validation_code.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="first_name"
                                        type="text"
                                        placeholder="First name"
                                        {...register("first_name", {
                                            required:
                                                "Please let us know your first name - we can't greet you nicely without this!"
                                        })}
                                    />
                                    {!!errors.first_name && (
                                        <div className="error-text">{errors.first_name.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="last_name"
                                        type="text"
                                        placeholder="Last name"
                                        {...register("last_name", {
                                            required:
                                                "Please let us know your last name - we can't greet you nicely without this!"
                                        })}
                                    />
                                    {!!errors.last_name && <div className="error-text">{errors.last_name.message}</div>}
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder="Parent's email"
                                        {...register("email", {
                                            validate: value =>
                                                emailRegex.test(value) ||
                                                "That email doesn't look right, can you check it's correct please?"
                                        })}
                                    />
                                    {!!errors.email && <div className="error-text">{errors.email.message}</div>}
                                </div>
                            </div>
                            <div className="code-container-row password-info">
                                <div className="password-info-text">
                                    Your password needs to be at least 8 characters.
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="password"
                                        type="password"
                                        placeholder="Password"
                                        {...register("password", {
                                            maxLength: {
                                                message: "Sorry, your password may not exceed 32 characters.",
                                                value: 32
                                            },
                                            minLength: {
                                                message: "Sorry, your password needs at least 8 characters.",
                                                value: 8
                                            }
                                        })}
                                    />
                                    {!!errors.password && <div className="error-text">{errors.password.message}</div>}
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-form-control">
                                    <input
                                        id="passwordConfirm"
                                        type="password"
                                        placeholder="Confirm Password"
                                        {...register("passwordConfirm", {
                                            required: "Please fill out this field",
                                            validate: value => value === password.current || "Whoops, these don't match"
                                        })}
                                    />
                                    {!!errors.passwordConfirm && (
                                        <div className="error-text">{errors.passwordConfirm.message}</div>
                                    )}
                                </div>
                            </div>
                            <div className="code-container-row">
                                <div className="code-row">
                                    <div className="code-form-control width-auto">
                                        <input
                                            id="age"
                                            className="age-input"
                                            type="number"
                                            placeholder="Age"
                                            {...register("age", {
                                                min: {
                                                    message: "You must be 18 or over to create an account",
                                                    value: 18
                                                },
                                                required: "Age is required"
                                            })}
                                        />
                                    </div>
                                    <div className="verify-text">Help us verify that you are an adult.</div>
                                </div>
                                {!!errors.age && <div className="error-text uk-width-full">{errors.age.message}</div>}
                            </div>
                            <div className="highlighted-row">
                                <div className="code-container-row">
                                    <div className="code-row checkbox-row">
                                        <input
                                            id="data_storing_consent"
                                            type="checkbox"
                                            {...register("data_storing_consent", { required: "You must agree" })}
                                        />
                                        <label className="checkbox-label">
                                            I agree to my information being used as set out in the{" "}
                                            <Link to="/cookies-and-privacy-policy">Privacy Policy.</Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="highlighted-row transparent">
                                <div className="code-container-row">
                                    <div className="code-row checkbox-row">
                                        <input id="weekly_updates" type="checkbox" {...register("weekly_updates")} />
                                        <label className="checkbox-label">Receive email updates from Hopster</label>
                                    </div>
                                </div>
                            </div>
                            <div className="code-container-row submit-row">
                                <button type="submit" disabled={working || !agreesToPrivacy.current}>
                                    {working ? "Working..." : "Let's Go!"}
                                </button>
                            </div>
                            <div className="highlighted-row legal-row">
                                By creating a Hopster account, you agree to the{" "}
                                <Link to="/terms-of-use">Terms of Use</Link>.
                            </div>
                        </>
                    )}
                </div>
            </form>
        </Layout>
    );
}

VoucherRedemption.propTypes = {
    partner: PropTypes.string.isRequired
};

export default VoucherRedemption;
